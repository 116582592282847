import React from 'react';
import Transition from 'react-transition-group/Transition';
import { withState } from 'recompose';

const withInState = withState('in', 'setIn', true);

export default ({ duration }) => Component =>
  withInState(({ in: inProp, setIn, ...props }) => {
    const defaultStyle = {
      transition: `opacity ${duration}ms ease-in-out`,
      opacity: 0,
    };

    const transitionStyles = {
      entering: { opacity: 0 },
      entered: { opacity: 1 },
    };

    return (
      <Transition in={inProp} timeout={duration}>
        {state => (
          <Component
            onClick={() => {
              setIn(false);
              setTimeout(() => setIn(true), 25);
            }}
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
            {...props}
          />
        )}
      </Transition>
    );
  });
