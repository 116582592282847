import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withState } from 'recompose';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  height: 100%;
  color: var(--dark);
  letter-spacing: -0.02;
  font-weight: bold;
  // font-family: b;
`;

const Input = styled.input`
  font-size: 1.4rem;
  height: 100%;
  color: ${({ active }) => `var(--${active ? 'pink' : 'dark'})`};
  letter-spacing: -0.02;
  margin-bottom: 0.2rem;
`;

const DarkLine = styled.div`
  height: 2px;
  background-color: var(--dark);
  width: 100%;
`;

export const AuthInput = ({
  className,
  value,
  onChange,
  placeholder,
  type,
  active,
  setActive,
}) => (
  <Wrapper className={className}>
    <Label>{placeholder}</Label>
    <Input
      value={value}
      onChange={onChange}
      type={type}
      active={active}
      onFocus={() => setActive(true)}
      onBlur={() => setActive(false)}
    />
    <DarkLine />
  </Wrapper>
);

AuthInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  active: PropTypes.bool.isRequired,
  setActive: PropTypes.func.isRequired,
};

AuthInput.defaultProps = {
  type: 'text',
};

const enhance = withState('active', 'setActive', false);
export default enhance(AuthInput);
