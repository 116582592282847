import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.button`
  flex-direction: row;
  box-shadow: 0 6px 34px var(--shadow);
  border-radius: 11px;
  padding: 1rem 2rem;
  cursor: pointer;
  background-color: white;
  &:hover {
    box-shadow: 0 6px 34px var(--dark_shadow);
  }
`;

const Card = ({ children, ...props }) => (
  <Wrapper {...props}>{children}</Wrapper>
);

Card.propTypes = {
  children: PropTypes.shape(),
};

export default Card;
