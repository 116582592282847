import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';

import withFade from 'components/hocs/withFade';
import Error from 'components/styled/AuthFormError';
import CardButton from 'components/styled/CardButton';
import AuthInput from 'components/atoms/AuthInput';
import emailSrc from 'assets/icons/mail.svg';
import margins from 'styles/_margins.module.scss';

import styles from './styles.module.scss';

const CardButtonWithFade = withFade({ duration: 100 })(CardButton);

const Form = ({
  pass,
  setPass,
  passConf,
  setPassConf,
  error,
  resetPassword,
}) => (
  <form onSubmit={resetPassword}>
    <AuthInput
      value={pass}
      onChange={(e) => setPass(e.target.value)}
      placeholder="SENHA"
      type="password"
      className={margins.mb20}
    />
    <AuthInput
      value={passConf}
      onChange={(e) => setPassConf(e.target.value)}
      placeholder="CONFIRMAR SENHA"
      type="password"
    />
    <Error className={margins.mt20}>{error != null && error.text}</Error>
    <CardButtonWithFade className={styles.btnWrapper} type="submit">
      <SVG src={emailSrc} className={styles.mail_icon} />
      <p>Confirmar</p>
    </CardButtonWithFade>
  </form>
);

Form.propTypes = {
  error: PropTypes.shape(),
  pass: PropTypes.string.isRequired,
  passConf: PropTypes.string.isRequired,
  setPass: PropTypes.func.isRequired,
  setPassConf: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
};

export default Form;
