import React from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';

import Column from 'components/styled/Column';
import iconSrc from 'assets/icons/success.svg';

const Col = Column({ alignCenter: true, center: true });

const Message = styled.p`
  margin-top: 3rem;
  font-size: 2rem;
  padding: 0rem 4rem;
  text-align: center;
`;

const Logo = styled(SVG)`
  width: 6rem;
  height: 6rem;
`;

const Success = () => (
  <Col>
    <Logo src={iconSrc} />
    <Message>A sua senha foi alterada com sucesso.</Message>
  </Col>
);

export default Success;
