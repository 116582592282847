import React from 'react';
import PropTypes from 'prop-types';
import { compose, withState, withProps } from 'recompose';
import styled from 'styled-components';

import Layout from 'components/layout';
import Card from 'components/styled/Card';
import wrappers from 'styles/_wrappers.module.scss';

import Success from 'components/molecules/ResetPassword/Success';
import Form from 'components/molecules/ResetPassword/Form';
import { resetPassword } from 'core/api';

const Container = styled.div`
  @media (min-width: 750px) {
    padding: 15rem 2rem 10rem 2rem;
  }
  padding: 10rem 2rem;
  text-align: center;
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const ResetPasswordScreen = props => (
  <Layout location={props.location} title="Reset de password">
    <Container>
      <Card className={wrappers.card_auth_wrapper}>
        {props.error != null && props.error.ok ? (
          <Success />
        ) : (
          <Form {...props} />
        )}
      </Card>
    </Container>
  </Layout>
);

ResetPasswordScreen.propTypes = {
  error: PropTypes.shape(),
};

export default compose(
  withState('error', 'setError', ''),
  withState('pass', 'setPass', ''),
  withState('passConf', 'setPassConf', ''),
  withProps(props => ({
    resetPassword: async (e) => {
      e.preventDefault();

      if (props.pass !== props.passConf) {
        return props.setError({
          text: 'Senhas não coincidem',
        });
      } else if (props.pass.length < 6) {
        return props.setError({
          text: 'Senha demasiado curta',
        });
      }
      const url = new URL(props.location.href);
      const token = url.searchParams.get('token');
      const status = await resetPassword(props.pass, token);

      props.setError(status);
    },
  })),
)(ResetPasswordScreen);
